//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { flowableInstanceMy } from '@/api/modular/flowable/instanceManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import seeForm from './seeForm'
import moment from 'moment'
import InstanceTask from './instanceTask'
import tracking from '../tracking/tracking'
export default {
  components: {
    XCard,
    InstanceTask,
    STable,
    Ellipsis,
    seeForm,
    tracking
  },
  data () {
    return {
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '流程名稱',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '發起人',
          dataIndex: 'startUserName'
        },
        {
          title: '發起時間',
          dataIndex: 'formatStartTime',
          scopedSlots: { customRender: 'formatStartTime' }
        },
        {
          title: '結束時間',
          dataIndex: 'formatEndTime',
          scopedSlots: { customRender: 'formatEndTime' }
        },
        {
          title: '是否結束',
          dataIndex: 'ended',
          scopedSlots: { customRender: 'ended' }
        },
        {
          title: '是否掛起',
          dataIndex: 'suspended',
          scopedSlots: { customRender: 'suspended' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        const dates = this.queryParam.dates
        if (dates != null) {
          this.queryParam.searchBeginTime = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
          this.queryParam.searchEndTime = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
          if (dates.length < 1) {
            delete this.queryParam.searchBeginTime
            delete this.queryParam.searchEndTime
          }
        }
        const obj = JSON.parse(JSON.stringify(this.queryParam))
        delete obj.dates
        return flowableInstanceMy(Object.assign(parameter, obj)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      suspendedDictTypeDropDown: [],
      loading: false,
      flowableCategoryListData: [],
      endedDictTypeDropDown: [],
      instanceShow: true,
      instanceTaskShow: false
    }
  },
  created () {
    this.sysDictTypeDropDown()
    this.flowableCategoryList()
    this.columns.push({
      title: '操作',
      width: '150px',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' }
    })
  },
  methods: {
    moment,
    openInstanceTaskCom (record) {
      this.instanceShow = false
      this.instanceTaskShow = true
      this.$refs.InstanceTask.openInstanceTask(record)
    },
    /**
     * 獲取流程類型
     */
    flowableCategoryList () {
      flowableCategoryList().then((res) => {
        this.flowableCategoryListData = res.data
      })
    },
    suspendedFilter (suspended) {
      // eslint-disable-next-line eqeqeq
      const values = this.suspendedDictTypeDropDown.filter(item => item.code == suspended.toString())
      if (values.length > 0) {
        return values[0].value
      }
    },
    endedFilter (ended) {
      // eslint-disable-next-line eqeqeq
      const values = this.endedDictTypeDropDown.filter(item => item.code == ended.toString())
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'suspended_status' }).then((res) => {
        this.suspendedDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'ended_status' }).then((res) => {
        this.endedDictTypeDropDown = res.data
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    handleReturn () {
      this.instanceShow = true
      this.instanceTaskShow = false
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
